<template>
    <h1>Successfully logged out</h1>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',

  mounted () {
    this.logout()
  },

  methods: {
    ...mapActions('user', ['logout'])
  }
}
</script>

<style scoped>

</style>
